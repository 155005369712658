:root {
  --steel3: #e6e9f4;
  --danger: #f0142f;
  --base-font-size: 0.875rem;
  --hover: #e1ebff;
  --primary-tint-3: #bfd5ff;
  --primary-tint-4: #80acff;
  --primary-tint-5: #4082ff;
  --primary: #0058ff;
  --accent-tint-2: #dca7ff;
  --accent-tint-1: #ecd3ff;
  --gray-1: #e6e6e6;
}
.rc-v1 abbr {
  text-decoration: none;
  font-size: 0.875rem;
  font-family: inherit;
}
.react-calendar.rc-v1 {
  font-family: var(--font-default);
  width: 350px;
  max-width: 100%;
  background: white;
  min-height: 19.25rem;
  font-size: var(--base-font-size);
  line-height: 1.125em;
  border-radius: 0.625rem;
  box-shadow: 0 10px 8px rgb(0 0 0 / 0.04);
  border: 1px solid;
  border-color: var(--steel3);
  overflow: hidden;
  user-select: none;
}

.react-calendar.rc-v1,
.react-calendar.rc-v1 *,
.react-calendar.rc-v1 *:before,
.react-calendar.rc-v1 *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar.rc-v1 button {
  margin: 0;
  border: 0;
  outline: none;
  font-family: inherit;
}
.react-calendar.rc-v1 button:enabled:hover {
  cursor: pointer;
}
.rc-v1 .react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.rc-v1 .react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.rc-v1 .react-calendar__navigation button:disabled {
  background-color: var(--gray-1);
}
.rc-v1 .react-calendar__navigation button:enabled:hover,
.rc-v1 .react-calendar__navigation button:enabled:focus {
  background-color: var(--hover);
}
.rc-v1 .react-calendar__navigation__label {
  font-size: var(--base-font-size);
}
.rc-v1 .react-calendar__navigation__label__labelText {
  font-family: inherit;
  font-size: inherit;
}
.rc-v1 .react-calendar__navigation__arrow {
  font-size: 1.25rem;
  font-family: inherit;
}
.rc-v1 .react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75em;
}
.rc-v1 .react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  font-size: 0.75rem;
}
.rc-v1 .react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.rc-v1 .react-calendar__month-view__days__day {
  font-family: inherit;
  font-size: var(--base-font-size);
}
.rc-v1 .react-calendar__month-view__days__day--weekend {
  font-family: inherit;
  font-size: var(--base-font-size);
  color: var(--danger);
}

.rc-v1 .react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.rc-v1 .react-calendar__year-view .react-calendar__tile,
.rc-v1 .react-calendar__decade-view .react-calendar__tile,
.rc-v1 .react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.rc-v1 .react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  border-radius: 8px;
}
.rc-v1 .react-calendar__tile:disabled {
  background-color: #f0f0f0;
  border-radius: 0;
}
.rc-v1 .react-calendar__tile:enabled:hover,
.rc-v1 .react-calendar__tile:enabled:focus {
  background-color: var(--hover);
}
.rc-v1 .react-calendar__tile--now {
  background: var(--accent-tint-2);
}
.rc-v1 .react-calendar__tile--now:enabled:hover,
.rc-v1 .react-calendar__tile--now:enabled:focus {
  background: var(--accent-tint-1);
}
.rc-v1 .react-calendar__tile--hasActive {
  background: var(--primary-tint-4);
}
.rc-v1 .react-calendar__tile--hasActive:enabled:hover,
.rc-v1 .react-calendar__tile--hasActive:enabled:focus {
  background: var(--primary-tint-3);
}
.rc-v1 .react-calendar__tile--active {
  background: var(--primary);
  color: white;
}
.rc-v1 .react-calendar__tile--active:enabled:hover,
.rc-v1 .react-calendar__tile--active:enabled:focus {
  background: var(--primary-tint-5);
}
.rc-v1.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: var(--hover);
}

.rc-v1 .react-calendar__tile--rangeStart:not(.react-calendar__tile--rangeBothEnds),
.rc-v1 .react-calendar__tile--hoverStart:not(.react-calendar__tile--hoverBothEnds) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rc-v1 .react-calendar__tile--rangeEnd:not(.react-calendar__tile--rangeBothEnds),
.rc-v1 .react-calendar__tile--hoverEnd:not(.react-calendar__tile--hoverBothEnds) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rc-v1
  .react-calendar__tile--range:not(.react-calendar__tile--rangeEnd):not(
    .react-calendar__tile--rangeStart
  ),
.rc-v1
  .react-calendar__tile--hover:not(.react-calendar__tile--hoverEnd):not(
    .react-calendar__tile--hoverStart
  ) {
  border-radius: 0;
}
