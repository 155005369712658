body {
  z-index: 0;
  /* overflow-anchor: none !important;
  scroll-snap-stop: normal !important;
  overscroll-behavior: unset !important;
  scroll-behavior: unset !important; */
  /* overflow-x: hidden; */
  overflow-x: hidden;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  margin: 0;
  padding: 0;
  padding-right: 0 !important;
  z-index: 0;
}

div,
header {
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  background-color: #f5f6fa;
}

body::-webkit-scrollbar-thumb {
  background-color: #d7dbec;
}

input:disabled {
  -webkit-appearance: none;
  opacity: 1;
}

.no-y-overflow {
  overflow-y: hidden;
}
